<template>
  <div data-app>

    <div v-if="dataOri">
      <div v-if="form.rincian.length > 0">
        <div v-for="(rinc, index) in form.rincian" :key="index" class="border rounded p-3 mb-5">
          <div class="d-flex align-items-center mx-3">
            <div class="symbol symbol-35 symbol-light mr-4">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Communication/Group.svg"/>
                  <!--end::Svg Icon-->
                </span> 
              </span>
            </div>         
            <span class="text-dark-75 font-size-h5 font-weight-bolder mr-auto">
                {{ namePerusahaan(rinc.perusahaan) }}
            </span>
            <b-button 
            v-b-tooltip.hover title="Edit"
            class="btn-icon mr-1"
            variant="clean"
            @click="editRincian(index)">
              <span class="svg-icon svg-icon-lg m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                <!--end::Svg Icon-->
              </span>
            </b-button>
            
            <b-button 
            v-b-tooltip.hover title="Hapus"
            class="btn-icon"
            variant="clean"
            @click="deleteRincian(index)">
              <span class="svg-icon svg-icon-lg m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/General/Trash.svg"/>
                <!--end::Svg Icon-->
              </span>
            </b-button>
          </div>
          
          <!--begin::Separator-->
          <div class="separator separator-solid mt-3 mb-4"></div>
          <!--end::Separator-->

          <div v-for="(mgg, indexMgg) in rinc.minggu" :key="indexMgg" class="align-items-center mx-sm-7 mx-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-35 symbol-light mr-4">
                <span class="symbol-label">
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <a class="text-success text-hover-primary font-size-lg font-weight-bolder">{{ mgg.tgl_awal | dateIndonesia }} s/d {{ mgg.tgl_akhir  | dateIndonesia }}</a>
                <div class="text-muted font-weight-bold">
                  <span v-for="(child, indexChild) in mgg.child" :key="indexChild">
                    {{ (indexChild > 0 ? ', ':'') }} {{ nameSex(child.sex) }} {{ child.jumlah | nominal }} butir
                  </span>
                </div>
              </div>
              <!--end::Text-->
            </div>
            <!--begin::Separator-->
            <div class="separator separator-border-2 separator-light separator-dashed mb-2"></div>
            <!--end::Separator-->
          </div>
        </div>
      </div>
      <div v-else class="border rounded p-3 mb-5 text-center">
        <span class="text-dark-75 font-size-lg font-weight-bolder">Tidak ada data Cutting HE, silahkan tambah data</span>
      </div>
                    
      <b-row class="mb-5">
          <b-col cols="12" class="text-right">
              <b-button @click="addRincian" variant="light-primary">
              <i class="flaticon2-plus-1 p-0"></i>
              Add Perusahaan
              </b-button>
          </b-col>
      </b-row>

    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>
    
    <div class="mt-10">
      <button 
      @click="onSubmit"
      ref="kt_submit" 
      class="btn btn-primary font-weight-bold">
        <i class="la la-save"></i>
        Perbarui
      </button>

      &nbsp;

      <button
      @click="onReset"
      class="btn btn-clean font-weight-bold">
        <i class="la la-recycle"></i>
        Reset
      </button>
    </div>
    <!--end::Form-->

    <v-dialog v-model="dialogForm" persistent scrollable max-width="650px">
      <!--end::Top-->
      <perfect-scrollbar
      class="scroll"
      style="max-height: 91%; position: relative;"
      >
           
        <!--begin::Form-->
        <ValidationObserver ref="formAdd">
        <b-form @submit="onAdd" @reset="addRincian" v-if="show">

          <div class="card card-custom">
            <!--begin::Body-->
            <div class="card-body">              
              <validationProvider name="Perusahaan" rules="required" v-slot="{ valid, errors }">
                <b-form-group 
                :id="`input-cuttinghe-group-3`" 
                label="Perusahaan:" 
                :label-for="`input-cuttinghe-3`"
                :invalid-feedback="errors[0]"
                :state="valid">
                    <b-form-select2
                    :id="`input-cuttinghe-3`"
                    variant="solid"
                    size="lg"
                    :options="opsPerusahaan"
                    v-model="tump.perusahaan"
                    :state="valid"
                    >
                    </b-form-select2>
                </b-form-group>
              </validationProvider>

              <b-row v-for="(mgg, index) in tump.minggu" :key="index">
                <b-col cols="10" class="pt-5 pb-0">
                    <h6 class="text-muted">Minggu {{index+1}}</h6>
                </b-col>
                <b-col cols="2" class="text-right pt-5 pb-0 pr-0">
                    <b-button v-show="(tump.minggu.length > 1) ? true : false" @click="deleteMinggu(index)" size="sm" variant="link" class="text-hover-primary">
                    <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                    </b-button>
                </b-col>     
                <b-col cols="12" sm="6" class="pt-0 pb-0">
                    <validationProvider :name="`Minggu ${index} Tgl Awal`" rules="required" v-slot="{ valid, errors }">
                    <b-form-group 
                    :id="`input-cuttinghe-group-12-${index}`" 
                    label="Tgl Awal:" 
                    :label-for="`input-cuttinghe-12-${index}`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                        <b-form-datepicker
                          :id="`input-cuttinghe-12-${index}`"
                          class="form-control form-control-solid pr-0"
                          v-model="mgg.tgl_awal"
                          size="lg"
                          :state="valid"
                          ></b-form-datepicker>
                    </b-form-group>
                    </validationProvider>
                </b-col>
                <b-col cols="12" sm="6" class="pt-0 pb-0">
                    <validationProvider :name="`Minggu ${index+1} Tgl Akhir`" :rules="`required|before:${mgg.tgl_awal}`" v-slot="{ valid, errors }">
                    <b-form-group 
                    :id="`input-cuttinghe-group-2-${index}`" 
                    label="Tgl Akhir:" 
                    :label-for="`input-cuttinghe-2-${index}`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                        <b-form-datepicker
                          :id="`input-cuttinghe-2-${index}`"
                          class="form-control form-control-solid pr-0"
                          v-model="mgg.tgl_akhir"
                          :min="mgg.tgl_awal"
                          size="lg"
                          :state="valid"
                          ></b-form-datepicker>
                    </b-form-group>
                    </validationProvider>
                </b-col>
                <b-col cols="12">
                  <b-row v-for="(item, indexChild) in mgg.child" :key="indexChild" class="bg-hover-light rounded mb-2" @contextmenu.prevent="$refs.menu.open($event, {index, indexChild})">
                    <b-col cols="12" sm="6" class="pt-0 pb-0">
                        <validationProvider :name="`Minggu ${index+1}-${indexChild} Sex`" rules="required" v-slot="{ valid, errors }">
                        <b-form-group 
                        :id="`input-cuttinghe-group-${index}-${indexChild}-3`" 
                        label="Sex:" 
                        :label-for="`input-cuttinghe-${index}-${indexChild}-3`"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                            <b-form-select2
                            :id="`input-cuttinghe-${index}-${indexChild}-3`"
                            class="bg-hover-white"
                            variant="solid"
                            size="lg"
                            :options="opsSex"
                            v-model="item.sex"
                            :state="valid"
                            >
                            </b-form-select2>
                        </b-form-group>
                        </validationProvider>
                    </b-col>
                    <b-col cols="12" sm="6" class="pt-0 pb-0">
                        <validationProvider :name="`Minggu ${index+1}-${indexChild} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ valid, errors }">
                          <b-form-group 
                          :id="`input-cuttinghe-group-${index}-${indexChild}-2`" 
                          label="Jumlah:" 
                          :label-for="`input-cuttinghe-${index}-${indexChild}-2`"
                          :invalid-feedback="errors[0]"
                          :state="valid">
                              <vue-number-input 
                                :attrs="{ id: `input-cuttinghe-${index}-${indexChild}-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                                v-model="item.jumlah"
                                :min="1"
                                :max="9999999999"
                                size="large"
                                center
                                controls
                              ></vue-number-input>
                          </b-form-group>
                        </validationProvider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" class="pt-0 pb-0">
                  <!--begin::Separator-->
                  <div class="separator separator-solid mt-3 mb-4"></div>
                  <!--end::Separator-->
                </b-col>
              </b-row>
              <b-row>
                  <b-col cols="12" class="text-right">
                      <b-button @click="addMinggu" variant="clear-primary" class="text-hover-primary">
                      <i class="flaticon2-plus-1 p-0"></i>
                      Tambah Minggu
                      </b-button>
                  </b-col>
              </b-row>
              
            </div>
            <!--end::Body-->

            <div class="card-footer py-3">
              <button  
              ref="kt_submit_form"
              class="btn btn-size-sm btn-primary font-weight-bold m-3">
                  <i class="la la-save"></i>
                  Tambah
              </button>

              <button
              class="btn btn-size-sm btn-clean font-weight-bold m-3"
              type="button"
              @click="closeDialog()">
                  <i class="la la-recycle"></i>
                  Close
              </button>
            </div>
          </div>
        </b-form>
        </ValidationObserver>
      </perfect-scrollbar>
    </v-dialog>

  <vue-context ref="menu" v-slot="{ data }"
  class="navi navi-hover"
  :lazy="false"
  >
    <li class="navi-item">
        <a class="navi-link cursor-pointer" @click="addChild(data)">
            <span class="navi-icon"><i class="flaticon2-plus-1"></i></span>
            <span class="navi-text">Tambah</span>
        </a>
    </li>
    <li class="navi-item">
        <a class="navi-link cursor-pointer" @click="deleteChild(data)">
            <span class="navi-icon"><i class="flaticon2-rubbish-bin-delete-button"></i></span>
            <span class="navi-text">Delete</span>
        </a>
    </li>
  </vue-context>

  </div>
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import SEService from "@/core/services/api/master/se.service";
import ErrorService from "@/core/services/error.service";

import VueContext from 'vue-context';
import 'vue-context/src/sass/vue-context.scss';
import { extend } from 'vee-validate';

extend('before', {
  validate(value, { dateA }) {
    if(value >= dateA) return true;
    return 'Tidak boleh kurang dari Tanggal Awal';
  },
  params: ['dateA']
});


export default {
  name: "master-data-surat-edaran-edit-cutting-he",
  props: {
    idSE: {
      required: true
    }
  },
  components: {
    VueContext,
    BulletListLoader
  },  
  data() {
    return {
      dialogForm: false,
      dataOri: null,
      form: {
        se: this.idSE,
        rincian: []
      },
      onEdit: null,
      tump: {
        perusahaan: null,
        minggu:[{
          tgl_awal: null,  
          tgl_akhir: null,  
          child: [{
            sex: null,
            jumlah: 1
          }]
        }]
      },
      opsPerusahaan: [
        { text: 'Silahkan pilih Perusaahan', value: null },
      ],
      opsSex: [
        { text: 'Silahkan pilih Sex', value: null },
      ],
      show: true
    }
  },
  methods: {
    addRincian(){
      this.tump = {
        perusahaan: null,
        minggu:[{
          tgl_awal: null,  
          tgl_akhir: null,  
          child: [{
            sex: null,
            jumlah: 1
          }]
        }]
      }

      this.dialogForm = true
      this.onEdit = null
    },
    closeDialog() {
      this.dialogForm = false
    },
    editRincian(index){
      this.tump = this.form.rincian[index]

      this.dialogForm = true
      this.onEdit = index
    },
    deleteRincian(index){
      this.form.rincian.splice(index,1);
    },
    addMinggu(){
      this.tump.minggu.push({
        tgl_awal: null,  
        tgl_akhir: null,  
        child: [{
          sex: null,
          jumlah: 1
        }]
      })
    },
    deleteMinggu(index){
      if (this.tump.minggu.length > 1) {
        this.tump.minggu.splice(index,1);
      }
      else {
        this.tump.minggu = [{
          tgl_awal: null,  
          tgl_akhir: null,  
          child: [{
            sex: null,
            jumlah: 1
          }]
        }]
      }
    },
    addChild(data){
      this.tump.minggu[data.index].child.push({
        sex: null,
        jumlah: 1
      })
    },
    deleteChild(data){
      if (this.tump.minggu[data.index].child.length > 1) {
        this.tump.minggu[data.index].child.splice(data.indexChild,1);
      }
      else {
        this.tump.minggu[data.index].child = [{
          sex: null,
          jumlah: 1
        }]
      }
    },
    namePerusahaan (id) {
        if (!id) return null
        const perusahaan = this.opsPerusahaan.find(key => key.value === id)
        if(!perusahaan) return null
        return perusahaan.text
    },
    nameSex (id) {
        if (!id) return null
        const sex = this.opsSex.find(key => key.value === id)
        if(!sex) return null
        return sex.text
    },
    getPerusahaan() {
      PerusahaanService.getPerusahaan()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsPerusahaan = [{
                  value: null,
                  text: "Silahkan pilih Perusahaan"
                }]
                return;
            }

            this.opsPerusahaan = res.data.data
            this.opsPerusahaan.push({
              value: null,
              text: "Silahkan pilih Perusahaan"
            })
        })
        .catch((err) => {
          this.opsPerusahaan = [{
            value: null,
            text: "Tidak ada Perusahaan"
          }]
          ErrorService.status(err)
        })
    },
    getTernakSex() {
      MasterService.getTernakSex()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSex = [{
                  value: null,
                  text: "Silahkan pilih Sex"
                }]
                return;
            }

            this.opsSex = res.data.data
            this.opsSex.push({
              value: null,
              text: "Silahkan pilih Sex"
            })
        })
        .catch((err) => {
          this.opsSex = [{
            value: null,
            text: "Tidak ada Sex"
          }]
          ErrorService.status(err)
        })
    },
    getData () {
      SEService.getSECuttingHESingle({ se: this.idSE })
        .then((res) => {
            if (!res.data.status) {
                this.dataOri = JSON.stringify({ se:this.idSE, rincian:[] })
                this.$emit('error-show', true)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
        })
        .catch((err) => {
          this.dataOri = JSON.stringify({ se:this.idSE, rincian:[] })
          this.$emit('error-show', true)
          ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onAdd(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (this.tump.minggu.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Harap memilih mengisikan data perminggu',
            })
            return;
        }

        if(this.onEdit == null) {
          const cekDuplicate = this.form.rincian.find(key => key.perusahaan === this.tump.perusahaan);

          if (cekDuplicate) {
            ErrorService.message({
              action: 'Peringatan',
              message: `Double input Perusahaan ${this.namePerusahaan(this.tump.perusahaan)}, silahkan ubah terlebih dahulu`,
            })
            return;
          }
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit_form"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          if (this.onEdit != null) this.form.rincian.splice(this.onEdit,1,this.tump)
          else this.form.rincian.push(this.tump)
            this.dialogForm = false
            this.onEdit = null
          this.removeSpinner(submitButton)
        }, 800);
      })
    },
    onSubmit(evt) {
      evt.preventDefault()
      if (this.form.rincian.length == 0) {
          ErrorService.message({
            action: 'Peringatan',
            message: 'Harap memilih Data Perusahaan terlebih dahulu',
          })
          return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // setTimeout(() => {
          SEService.updateSECuttingHE(this.form)
              .then((res) => {
                  this.removeSpinner(submitButton)
                  ErrorService.message(res.data)
                  if (res.data.status) {
                    this.$emit('error-show', false)
                    this.dataOri = JSON.stringify(this.form)
                  }
                  else this.$emit('error-show', true)
                  
                  return;
              })
              .catch((err) => {
                this.removeSpinner(submitButton)
                this.$emit('error-show', true)
                ErrorService.status(err)
              })

      // }, 1000);
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form = JSON.parse(this.dataOri)

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Surat Edaran" }]);
    await this.getPerusahaan()
    await this.getTernakSex()
    this.getData()
  },
};
</script>