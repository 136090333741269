<template>
  <div>

    <div v-if="showPage">
      <!--begin::Form-->
      <ValidationObserver ref="formAdd">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">

        <b-row>
            <b-col cols="12" class="pt-5 pb-0">
                <h6 class="text-muted">Tanggal Afkir Dini</h6>
            </b-col>
            <b-col cols="12" sm="6" class="pt-0 pb-0">
                <validationProvider name="Tgl Awal Afkir Dini" rules="required" v-slot="{ valid, errors }">
                <b-form-group 
                id="input-group-12" 
                label="Tgl Awal:" 
                label-for="input-12"
                :invalid-feedback="errors[0]"
                :state="valid">
                    <b-form-datepicker
                      id="input-12"
                      class="form-control form-control-solid pr-0"
                      v-model="form.tgl_awal"
                      required
                      locale="id"
                      size="lg"
                      :state="valid"
                      ></b-form-datepicker>
                </b-form-group>
                </validationProvider>
            </b-col>
            <b-col cols="12" sm="6" class="pt-0 pb-0">
                <validationProvider name="Tgl Akhir Afkir Dini" rules="required" v-slot="{ valid, errors }">
                <b-form-group 
                id="input-group-2" 
                label="Tgl Akhir:" 
                label-for="input-2"
                :invalid-feedback="errors[0]"
                :state="valid">
                    <b-form-datepicker
                      id="input-2"
                      class="form-control form-control-solid pr-0"
                      v-model="form.tgl_akhir"
                      :min="form.tgl_awal"
                      locale="id"
                      size="lg"
                      required
                      :state="valid && beforeDate"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback :state="beforeDate">
                          Tidak boleh kurang dari Tanggal Awal {{ form.tgl_awal | dateIndonesia }}
                      </b-form-invalid-feedback>
                </b-form-group>
                </validationProvider>
            </b-col>
        </b-row>

          <b-row v-for="(rinc, index) in form.rincian" :key="index">
              <b-col cols="10" class="pt-5 pb-0">
                  <h6 class="text-muted">Perusahaan {{index + 1}}</h6>
              </b-col>
              <b-col cols="2" class="text-right pt-5 pb-0">
                  <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                  <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                  </b-button>
              </b-col>            
              <b-col cols="12" class="pt-0 pb-0">
                  <validationProvider :name="`Rin ${index+1} Perusahaan`" rules="required" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-${index}-3`" 
                  label="Perusahaan:" 
                  :label-for="`input-${index}-3`"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                      <b-form-select2
                      :id="`input-${index}-3`"
                      variant="solid"
                      size="lg"
                      :options="opsPerusahaan"
                      v-model="rinc.perusahaan"
                      required
                      :state="valid"
                      >
                      </b-form-select2>
                  </b-form-group>
                  </validationProvider>
              </b-col>     
              <b-col cols="12" class="pt-0 pb-0">    
                <b-row v-for="(item, key) in rinc.child" :key="key" class="w-full bg-hover-light rounded mb-2" @contextmenu.prevent="$refs.menu.open($event, {index, indexChild: key})">
                  <b-col cols="12" md="6" class="pt-0 pb-0">
                      <validationProvider :name="`Rin ${index+1}-${key} Sex`" rules="required" v-slot="{ valid, errors }">
                      <b-form-group 
                      :id="`input-group-${index}-${key}-3`" 
                      label="Sex:" 
                      :label-for="`input-${index}-${key}-3`"
                      :invalid-feedback="errors[0]"
                      :state="valid">
                          <b-form-select2
                          :id="`input-${index}-${key}-3`"
                          class="bg-hover-white"
                          variant="solid"
                          size="lg"
                          :options="opsSex"
                          v-model="item.sex"
                          required
                          :state="valid"
                          >
                          </b-form-select2>
                      </b-form-group>
                      </validationProvider>
                  </b-col>
                  <b-col cols="12" md="6" class="pt-0 pb-0">
                      <validationProvider :name="`Rin ${index+1}-${key} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ valid, errors }">
                        <b-form-group 
                        :id="`input-group-${index}-${key}-2`" 
                        label="Jumlah:" 
                        :label-for="`input-${index}-${key}-2`"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-${index}-${key}-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="item.jumlah"
                              :min="1"
                              :max="9999999999"
                              size="large"
                              required
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                      </validationProvider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="pt-0 pb-0">
                  <!--begin::Separator-->
                  <div class="separator separator-solid mt-3 mb-4"></div>
                  <!--end::Separator-->
              </b-col>
          </b-row>

          <b-row class="mb-5">
              <b-col cols="12" class="text-right">
                  <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                  <i class="flaticon2-plus-1 p-0"></i>
                  Add Perusahaan
                  </b-button>
              </b-col>
          </b-row>
      </b-form>
      </ValidationObserver>
      
      <div class="mt-10">
        <button 
        @click="onSubmit" 
        ref="kt_submit"
        class="btn btn-primary font-weight-bold">
          <i class="la la-save"></i>
          Perbarui
        </button>

        &nbsp;

        <button
        @click="onReset"
        class="btn btn-clean font-weight-bold">
          <i class="la la-recycle"></i>
          Reset
        </button>
      </div>
      <!--end::Form-->
    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>

    <vue-context ref="menu" v-slot="{ data }"
        class="navi navi-hover"
        :lazy="false"
    >
        <li class="navi-item">
            <a class="navi-link cursor-pointer" @click="addSubRincian(data)">
                <span class="navi-icon"><i class="flaticon2-plus-1"></i></span>
                <span class="navi-text">Tambah</span>
            </a>
        </li>
        <li class="navi-item">
            <a class="navi-link cursor-pointer" @click="deleteSubRincian(data)">
                <span class="navi-icon"><i class="flaticon2-rubbish-bin-delete-button"></i></span>
                <span class="navi-text">Delete</span>
            </a>
        </li>
    </vue-context>
  </div>
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import SEService from "@/core/services/api/master/se.service";
import ErrorService from "@/core/services/error.service";

import VueContext from 'vue-context';
import 'vue-context/src/sass/vue-context.scss';

export default {
  name: "master-data-surat-edaran-edit-afkir-dini",
  props: {
    idSE: {
      required: true
    }
  },
  components: {
    VueContext,
    BulletListLoader
  },  
  data() {
    return {
      showPage: false,
      dataOri: null,
      form: {
        se: this.idSE,
        tgl_awal: null,  
        tgl_akhir: null,  
        rincian: [{
          perusahaan: null,
          child: [{
            sex: null,
            jumlah: 1
          }]
        }]
      },
      opsPerusahaan: [
        { text: 'Silahkan pilih Perusahaan', value: null },
      ],
      opsSex: [
        { text: 'Silahkan pilih Sex', value: null },
      ],
      show: true
    }
  },
  computed: {
    beforeDate () {
      if(this.form.tgl_awal <= this.form.tgl_akhir) return true
      return false
    },
    cekPerusahaan () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.perusahaan] = ++a[e.perusahaan] || 0;
        return a;
      }, {});

      return this.opsPerusahaan.filter(e => lookup[e.value] && e.value !== null)
    },
    namePerusahaan () {
        if (this.cekPerusahaan.length > 0) {
            return this.cekPerusahaan.map(function(item) {return ` ${item['text']}`})
        }
        return ''
    },      
  },
  methods: {
    addRincian(){
      this.form.rincian.push({
        perusahaan: null,
        child: [{
          sex: null,
          jumlah: 1
        }]
      })
    },
    deleteRincian(index){
      if (this.form.rincian.length > 1) {
        this.form.rincian.splice(index,1);
      }
      else {
        this.form.rincian = [{
          perusahaan: null,
          child: [{
            sex: null,
            jumlah: 1
          }]
        }]
      }
    },    
    addSubRincian(data){
      this.form.rincian[data.index].child.push({
        sex: null,
        jumlah: 1
      })
    },
    deleteSubRincian(data){
      if (this.form.rincian[data.index].child.length > 1) {
        this.form.rincian[data.index].child.splice(data.indexChild,1);
      }
      else {
        this.form.rincian[data.index].child = [{
          sex: null,
          jumlah: 1
        }]
      }
    },
    getPerusahaan() {
      PerusahaanService.getPerusahaan()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsPerusahaan = [{
                  value: null,
                  text: "Silahkan pilih Perusahaan"
                }]
                return;
            }

            this.opsPerusahaan = res.data.data
            this.opsPerusahaan.push({
              value: null,
              text: "Silahkan pilih Perusahaan"
            })
        })
        .catch((err) => {
          this.opsPerusahaan = [{
            value: null,
            text: "Tidak ada Perusahaan"
          }]
          ErrorService.status(err)
        })
    },
    getTernakSex() {
      MasterService.getTernakSex()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSex = [{
                  value: null,
                  text: "Silahkan pilih Sex"
                }]
                return;
            }

            this.opsSex = res.data.data
            this.opsSex.push({
              value: null,
              text: "Silahkan pilih Sex"
            })
        })
        .catch((err) => {
          this.opsSex = [{
            value: null,
            text: "Tidak ada Sex"
          }]
          ErrorService.status(err)
        })
    },
    getData () {
      SEService.getSEAfkirDiniSingle({ se: this.idSE })
        .then((res) => {
            if (!res.data.status) {
                this.showPage = true
                this.$emit('error-show', true)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
            this.showPage = true
        })
        .catch((err) => {
          this.$emit('error-show', true)
          this.showPage = true
          ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (!this.beforeDate) {
            ErrorService.message({
              action: 'Peringatan',
              message: `Tidak boleh kurang dari Tanggal Awal ${ form.tgl_awal | dateIndonesia }`,
            })
            return;
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Harap memilih Data Perusahaan terlebih dahulu',
            })
            return;
        }

        if (this.cekPerusahaan.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: `Double input perusahaan: ${this.namePerusahaan}`,
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            SEService.updateSEAfkirDini(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    if (res.data.status) {
                      this.$emit('error-show', false)
                      this.dataOri = JSON.stringify(this.form)
                    }
                    else this.$emit('error-show', true)
                    
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  this.$emit('error-show', true)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form = JSON.parse(this.dataOri)

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Surat Edaran" }]);
    await this.getPerusahaan()
    await this.getTernakSex()
    this.getData()
  },
};
</script>