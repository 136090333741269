<template>
  <div>

    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/master-data/surat-edarans"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link cursor-pointer"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              id="tab-menu-surat-edaran"
              class="nav-link cursor-pointer active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              Surat Edaran
            </a>
          </li>
          <li class="nav-item" v-if="this.formOriginal.afkir_dini">
            <a
              id="tab-menu-afkir-dini"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              role="tab"
            >
              Afkir Dini 
              <span v-if="errAfkirDini" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <li class="nav-item" v-if="this.formOriginal.cutting_he">
            <a
              id="tab-menu-cutting-he"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              role="tab"
            >
              Cutting HE 
              <span v-if="errCuttingHE" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <ValidationObserver ref="formEditSE">
              <b-form @submit="onSubmitSE" @reset="onResetSE" v-if="showSE">
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">No. SE</label>
                    <div class="col-lg-9 col-xl-7">
                        <validationProvider name="Nomor Surat Edaran" rules="required|max:50" v-slot="{ valid, errors }">
                            <b-form-group
                                id="input-group-se-1" 
                                label-for="input-se-1"
                                :invalid-feedback="errors[0]"
                                class="mb-0"
                                :state="valid">
                                <b-form-input
                                autocomplete="off"
                                id="input-se-1"
                                v-model="form.no_se"
                                class="form-control form-control-solid"
                                required
                                size="lg"
                                placeholder="Nomor Surat Edaran"
                                :state="valid"
                                ></b-form-input>
                            </b-form-group>
                        </validationProvider>
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Perihal</label>
                    <div class="col-lg-9 col-xl-7">
                        <validationProvider name="Perihal" rules="required|min:5|max:200" v-slot="{ valid, errors }">
                            <b-form-group
                                id="input-group-se-2" 
                                label-for="input-se-2"
                                :invalid-feedback="errors[0]"
                                class="mb-0"
                                :state="valid">
                                <b-form-textarea
                                  id="input-se-2"
                                  v-model="form.perihal"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="Perihal Surat Edaran"
                                  :invalid-feedback="errors[0]"
                                  :state="valid"
                                ></b-form-textarea>
                            </b-form-group>
                        </validationProvider>
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Tgl. SE</label>
                    <div class="col-lg-9 col-xl-7">
                        <validationProvider name="Tanggal Surat Edaran" rules="required" v-slot="{ valid, errors }">
                            <b-form-group
                                id="input-group-se-3" 
                                label-for="input-se-3"
                                :invalid-feedback="errors[0]"
                                description="pilih tanggal surat edaran sesuai yang ada di surat."
                                class="mb-0"
                                :state="valid">
                                <b-form-datepicker
                                  id="input-se-3"
                                  class="form-control form-control-solid pr-0"
                                  v-model="form.tgl_se"
                                  size="lg"
                                  required
                                  placeholder="Tanggal Surat Edaran"
                                  :state="valid"
                                ></b-form-datepicker>
                            </b-form-group>
                        </validationProvider>
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Data SE</label>
                    <div class="col-lg-9 col-xl-7">
                        <div>
                          <div class="checkbox-inline">
                              <label class="checkbox">
                                  <input type="checkbox" v-model="form.cutting_he"/>
                                  <span></span>
                                  Cutting HE
                              </label>
                              <label class="checkbox">
                                  <input type="checkbox" v-model="form.afkir_dini"/>
                                  <span></span>
                                  Afkir Dini
                              </label>
                          </div>
                          <span v-if="dataSEError" class="invalid-feedback d-block">The Data SE field is required</span>
                      </div>
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">File SE</label>
                    <div class="col-lg-9 col-xl-7">
                      <a v-if="tempAttachments.length > 0" @click="removefiles" class="btn btn-light-primary font-weight-bold">Remove files</a>
                      <vue-dropzone
                        ref="myVueDropzone"
                        :include-styling="false"
                        :useCustomSlot="true"
                        id="dropzone"
                        @vdropzone-file-added="fileAdded"
                        @vdropzone-upload-progress="uploadProgress"
                        @vdropzone-success="uploadSuccess"
                        @vdropzone-error="uploadError"
                        :options="dropzoneOptions"
                      >
                        <a v-if="tempAttachments.length == 0" class="btn btn-light-primary font-weight-bold">Attach files</a>
                        <span v-else></span>
                      </vue-dropzone>
                      <span class="form-text text-muted">Ukuran file Maksimal 1MB (MegaByte) </span>

                      <div v-if="tempAttachments.length > 0" class="mt-3">  
                        <div v-for="(item, key) in tempAttachments" :key="key" class="alert alert-light" :class="{'cursor-pointer': !item.message}" @click="downloadPdf(item)">
                          <p> {{ item.title }} ({{ (item.size/1000000).toFixed(2) }} MB) </p>
                          <b-progress height="7px" :value="item.progress" :max="100" show-progress animated></b-progress>
                          <span v-if="item.message" class="form-text text-danger">{{ item.message }}</span>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="mt-10">
                  <button 
                  @click="onSubmitSE" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold">
                    <i class="la la-save"></i>
                    Perbarui
                  </button>

                  &nbsp;

                  <button
                  @click="onResetSE"
                  class="btn btn-clean font-weight-bold">
                    <i class="la la-recycle"></i>
                    Reset
                  </button>
                </div>

              </b-form>
              </ValidationObserver>    
            </b-tab>
            <b-tab>
              <div v-if="this.formOriginal.afkir_dini">
                <EditAfkirDini :idSE="formOriginal.se" @error-show="onErrAfkirDini"></EditAfkirDini>
              </div>
            </b-tab>
            <b-tab>
              <div v-if="this.formOriginal.cutting_he">
                <EditCuttingHE :idSE="formOriginal.se" @error-show="onErrCuttingHE"></EditCuttingHE>
              </div>
            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SEService from "@/core/services/api/master/se.service";
import ErrorService from "@/core/services/error.service";
import JwtService from "@/core/services/jwt.service";

import EditAfkirDini from "@/view/pages/master-data/surat-edaran/edit-data/AfkirDini.vue";
import EditCuttingHE from "@/view/pages/master-data/surat-edaran/edit-data/CuttingHE.vue";

import vue2Dropzone from 'vue2-dropzone'

export default {
  name: "master-data-surat-edaran-edit",
  props: {
    idSE: {
      required: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      dataOri: null,
      form: {
        se: this.idSE,
        no_se: null,
        perihal: null,
        tgl_se: null,
        file: null,
        afkir_dini: null,
        cutting_he: null
      },
      tempAttachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL }/v2/master/surat-edaran/file`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        maxFilesize: 1000,
        maxFiles: 1,
        acceptedFiles: 'application/pdf',
        includeStyling: false,
        previewsContainer: false,
        parallelUploads: 20
      },
      showSE: true,
      errAfkirDini: false,
      errCuttingHE: false,
      redirectTab: true
    }
  },
  components: {
    EditAfkirDini,
    EditCuttingHE,
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapGetters(["currentUser"]),
    dataSEError () {
        return !this.form.afkir_dini && !this.form.cutting_he
    },
    formOriginal () {
      if(!this.dataOri) return {afkir_dini: false, cutting_he: false}
      return JSON.parse(this.dataOri)
    }, 
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },    
    /**
     * Set current active on route
     * @param id
     */
    setActiveTabRoute(id) {
      if (!this.form.cutting_he && !this.form.afkir_dini) {
        return
      }

      if (id != 'surat-edaran' && id != 'afkir-dini' && id != 'cutting-he') {
        return
      }

      const event = document.getElementById(`tab-menu-${id}`)
      const tab = event.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.getAttribute("data-tab"));

      // set current active tab
      event.classList.add("active");

      this.redirectTab = false
    }, 
    onErrAfkirDini(val) {
      this.errAfkirDini = val
    },
    onErrCuttingHE(val) {
      this.errCuttingHE = val
    },
    removefiles () {
        this.$refs.myVueDropzone.removeAllFiles(true);
        this.form.file = null
        this.tempAttachments= [];
    },
    fileAdded(file) {      
      let attachment = {};
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = "file";
      attachment.extension = "." + file.type.split("/")[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.filename = null;
      attachment.thumb = true;
      attachment.size = file.size;
      attachment.message = null;
      this.tempAttachments = [...this.tempAttachments, attachment];

    },
    uploadProgress(file, progress, bytesSent) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.progress = `${Math.floor(progress)}`;
        }
      });
    },
    uploadSuccess(file, response) {
      this.form.file = response.data
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.filename = response.data;
        }
      });

      ErrorService.message(response)
    },
    uploadError(file, message, xhr) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.message = message;
        }
      });
    },
    downloadPdf(file) {
      let thumb = '';
      if (file.filename && file.progress == 100 && !file.message) {
        if (file.thumb) {
          thumb = 'thum/'
        }
        window.open(`${process.env.VUE_APP_API_URL}/dokumen/surat-edaran/${thumb}${file.filename}`, "_blank"); 
      }
    },
    getData () {
      SEService.getSESingle({ se: this.idSE })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
            let attachment = {};
            attachment._id = res.data.data.file;
            attachment.title = res.data.data.file;
            attachment.type = "file";
            attachment.extension = ".pdf";
            attachment.isLoading = true;
            attachment.progress = 100;
            attachment.filename = res.data.data.file;
            attachment.size = 1000000;
            attachment.thumb = false;
            attachment.message = null;
            this.tempAttachments = [...this.tempAttachments, attachment];

            this.$nextTick(() => {
              if (this.$route.query.tab && this.redirectTab) {
                this.setActiveTabRoute(this.$route.query.tab)
              }
            })

        })
        .catch((err) => ErrorService.status(err))
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmitSE(evt) {
      evt.preventDefault()
      this.$refs.formEditSE.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (!this.form.afkir_dini && !this.form.cutting_he) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Harap memilih Data SE terlebih dahulu',
            })
            return;
        }

        if (!this.form.file) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Upload file SE untuk menyimpan data',
            })
            return;
        }
        
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            SEService.updateSE({ ...this.form, afkir_dini: !!this.form.afkir_dini, cutting_he: !!this.form.cutting_he })
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.dataOri = JSON.stringify(this.form)
                      this.tempAttachments.map(attachment => {
                        if (attachment.filename === this.form.file) {
                          attachment.thumb = false;
                        }
                      });
                    }

                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onResetSE(evt) {
      evt.preventDefault()
      // Reset our form values

      this.removefiles();
      
      this.form = JSON.parse(this.dataOri)
      let attachment = {};
      attachment._id = this.form.file;
      attachment.title = this.form.file;
      attachment.type = "file";
      attachment.extension = ".pdf";
      attachment.isLoading = true;
      attachment.progress = 100;
      attachment.filename = this.form.file;
      attachment.size = 1000000;
      attachment.thumb = false;
      attachment.message = null;
      this.tempAttachments = [...this.tempAttachments, attachment];
        
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Surat Edaran" }]);
    if (!this.currentUser.menu.surat_edaran) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
